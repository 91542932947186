import { BoxProps } from "@chakra-ui/react";
import { Image } from "../image/model/image.model";

export type ContentSection =
  | TextProps
  | ImagesTextGridProps
  | MultiParagraphImageBannerProps
  | BackgroundImageContentProps
  | any;

// ELEMENTS
export interface Paragraph {
  content: string;
  reactIconName?: string;
  title: string;
  link?: string;
}

export enum MultiParagraphImageBannerConfiguration {
  sacraments,
  groups,
  custom
}

export enum ButtonTypes {
  primary = "primary",
  secondary = "secondary"
}

export interface ImageParagraphProps {
  
  content: string;
  image: any;
  isImageOnTheLeft?: boolean;
  light?: boolean;
}

export interface Button {
  text: string;
  link: string;
  external: boolean;
  type: ButtonTypes;
}

export interface SlideProps {
  image: Image;
  buttons: Button[];
  heading: TextProps;
  next?: any;
  prev?: any;
}

export interface ImageLink {
  image: Image;
  url: string;
}

//////////////////////////////////////
// SECTIONS
//////////////////////////////////////

export interface TextProps extends BoxProps {
  author?: string;
  date?: string;
  content?: string;
  info?: string;
  superscript?: string;
  title?: string;
  light?: boolean;
}

export interface ImagesTextGridProps {
  heading: TextProps;
  paragraphs: ImageParagraphProps[];
}

export interface MultiParagraphImageBannerProps {
  paragraphs: Paragraph[];
  heading: TextProps;
  backgroundImage: Image;
  configuration: MultiParagraphImageBannerConfiguration;
}

export interface BackgroundImageContentProps {
  backgroundImage: Image;
  imageParagraph: ImageParagraphProps;
}

export interface SlideshowProps {
  slides: SlideProps[];
  useGlobalConfiguration: boolean;
}

export interface GalleryProps {
  images: Image[];
  heading: TextProps;
  button: Button;
}

export interface IframesProps {
  iframes: {
    url: string;
  }[];
  heading?: TextProps;
  button?: Button;
}
export interface AnnouncementsSectionProps {
  useMostRecent: boolean;
  backgroundImage: Image;
  heading: TextProps;
  announcements: any[];
}

export interface EventsSectionProps {
  useMostRecent?: boolean;
  gridGap?: number;
  heading?: TextProps;
  events: any[];
}

export interface ImageLinksProps {
  heading: TextProps;
  images: ImageLink[];
}

export interface PartnersSectionProps {
  heading: TextProps;
  partners: ImageLink[];
}
